import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import '../styles/Investors.css';
import { Link } from 'react-router-dom';
import DivInfo from '../assets/divinfo.png';
import Coin from '../assets/coin.png';
import CorpPres from '../assets/corppres.png';
import Video from '../assets/video.png';
import Report from '../assets/report.png';
import News from '../assets/news.png';
import BOD from '../assets/BOD.png';
import Analysts from '../assets/Analysts.png';
import Desjardins from '../assets/Desjardins.png';
import Cormark from '../assets/Cormark.png';
import Haywood from '../assets/Haywood.png';
import Paradigm from '../assets/Paradigm.jpg';
import PIFinacial from '../assets/pifinancial.jpg'
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumb } from 'semantic-ui-react';
import Presentation from '../assets/ttr_Presentation_Q2_2024.pdf';
import FactSheet from '../assets/Fact_Sheet.pdf';
import { Container, Row, Col, Card } from 'react-bootstrap'

import PopupVideo from "../components/PopupVideo.js";


require('dotenv').config();

// Load the AWS SDK for Node.js
//var AWS = require("aws-sdk");

//const SES_CONFIG = {
//    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
//    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//    region: 'us-east-1',
//};

//const AWS_SES = new AWS.SES(SES_CONFIG);

const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'investors', content: 'INVESTORS', active: true },

]

// function handleClick(event) {
//     event.preventDefault();
// }

function Investors() {
    {/**breadcrumb */ }
    // const useStyles = makeStyles((theme) => ({
    //     root: {
    //         '& > * + *': {
    //             marginTop: theme.spacing(2),
    //         },
    //     },
    // }));

    
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [subscriptionFormMessage, setSubscriptionFormMessage] = useState("");

    function sendEmail(e) {
        //https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/ses-examples-sending-email.html
        //https://betterprogramming.pub/how-to-send-emails-with-node-js-using-amazon-ses-8ae38f6312e4

        e.preventDefault();
        setSubscriptionFormMessage("");

        var emailBody = `${e.target.name.value} ${e.target.email.value} ${e.target.message.value}`;


        let body = {
            "subject": "Investor Contact Request", 
            "body": emailBody, 
            "recipient": "investors@ttgi.com"
        }

        // create the headers for the fetch 
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Content-Type', 'application/json');

        // add the orders via api call
        fetch('https://api.ttgi.com/wwwEmail/send', {
            headers: myHeaders,
            method: "POST",
            body: JSON.stringify(body)
        }).then((response) => {
            //set the error message
            if (response.status != 200) {                
                console.log(response);
                setSubscriptionFormMessage('Request successfully sent!');
            }
            //return response.json();
        })
            //.then(data => {
                
            //})
            .catch(function (error) {
                setSubscriptionFormMessage('There was an error processing your request. Please try again later or contact investors@ttgi.com.');
            });

        //// Create sendEmail params 
        //var params = {
        //    Destination: { /* required */
        //        CcAddresses: [
        //            // 'dev@ttgi.com',
        //            /* more items */
        //        ],
        //        ToAddresses: [
        //            'investors@uoguelph.ca'
        //            /* more items */
        //        ]
        //    },
        //    Message: { /* required */
        //        Body: { /* required */
        //            Html: {
        //                Charset: "UTF-8",
        //                Data: emailBody
        //            },
        //            Text: {
        //                Charset: "UTF-8",
        //                Data: emailBody
        //            }
        //        },
        //        Subject: {
        //            Charset: 'UTF-8',
        //            Data: 'Quote Request'
        //        }
        //    },
        //    Source: 'donotreply@ttgi.com', /* required */
        //    ReplyToAddresses: [
        //        'donotreply@ttgi.com',
        //        /* more items */
        //    ],
        //};

        //// Create the promise and SES service object
        //var sendPromise = AWS_SES.sendEmail(params).promise();

        //// Handle promise's fulfilled/rejected states
        //sendPromise.then(
        //    function (data) {
        //        console.log(data.MessageId);
        //        setSubscriptionFormMessage('Request successfully sent!');
        //    }).catch(
        //        function (err) {
        //            setSubscriptionFormMessage('There was an error processing your request. Please try again later or contact investors@ttgi.com.');
        //            console.error(err, err.stack);
        //        });

        e.target.reset();
    }


    useEffect(() => {
        const scriptTag = document.createElement('script');

        scriptTag.src = "https://s3.tradingview.com/tv.js";
        scriptTag.async = true;

        scriptTag.onload = () => {
            new window.TradingView.widget(
                {

                    "autosize": true,
                    "symbol": "TSX:TTNM",
                    "range": "1M",
                    "timezone": "America/Toronto",
                    "theme": "light",
                    "style": "3",
                    "locale": "en",
                    "toolbar_bg": "#f1f3f6",
                    "enable_publishing": false,
                    "hide_top_toolbar": true,
                    "withdateranges": true,
                    "save_image": false,
                    "container_id": "tradingview_8fbfe"
                }
            )
        }
        document.body.appendChild(scriptTag);
        // return () => {
        //     document.body.removeChild(scriptTag);
        // }
    }, []);





    return (
        <>
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff' }} />
            </div>

            <div className="investors-header" >
                <h2 style={{ color: '#0D1643', fontSize: '2rem !important' }}><b>Investor Relations</b></h2>
            </div>

            {open && <PopupVideo  open={open} close={onCloseModal} />}

            <div className="investors-content ">
                <Container fluid>
                    <Row className="align-items-center ">
                        <div id="inventor-top">
                            <div className="icons-list-align-left" >
                                <ul style={{ listStyle: 'none', display: 'inline-block', textAlign: 'left' }}>
                                    <li>
                                        <Link to="/dividendinformation" ><img className="investor-icons" src={DivInfo} alt="dividend information" style={{ marginTop: '10px' }} />     Dividend Information</Link>< br />
                                    </li>
                                    <li>
                                        {/* to={{ pathname: "https://vimeo.com/687947777/ee08ec7b0b" }} target="_blank" */}
                                        {/*<Link onClick={onOpenModal} ><img className="investor-icons" src={Video} alt="company video" />     Company Video</Link> <br />*/}
                                        <a onClick={onOpenModal} href="#"><img className="investor-icons" src={Video} alt="Link to Company Video" />     Company Video</a> <br />
                                    </li>
                                    <li>
                                        <a href={Presentation} target="_blank"><img className="investor-icons" src={CorpPres} alt="Link to Corporate Presentation" />     Corporate Presentation</a> <br />
                                    </li>
                                    <li>
                                        <a href={FactSheet} target="_blank"><img className="investor-icons" src={News} alt="Link to Fact Sheet" />     Fact Sheet</a> <br />
                                    </li>
                                    <li>
                                        <Link to={{ pathname: "https://www.sedar.com/DisplayProfile.do?lang=EN&issuerType=03&issuerNo=00003034" }} target="_blank" ><img className="investor-icons" src={Coin} alt="Link to TTNM on SEDAR" />     TTNM on SEDAR</Link> <br />
                                    </li>
                                    <li>
                                        <Link to="/financialreports" ><img className="investor-icons" src={Report} alt="Link to Financial Reports" />     Financial Reports</Link> <br />
                                    </li>
                                    <li>
                                        <Link to="/newsandpress" ><img className="investor-icons" src={News} alt="Link to News and Press" />     News & Press</Link> <br />
                                    </li>
                                    <li>
                                        <Link to="/boardofdirectors" ><img className="investor-icons" src={BOD} alt="Link to Board of Directors" />     Board of Directors</Link> <br />
                                    </li>
                                    <li>
                                        <Link to="/whistleBlower" ><img className="investor-icons" src={News} alt="Link to Whishle Blower Policy" />     Whistleblower</Link> <br />
                                    </li>


                                </ul>

                            </div>

                            <div className="tradingview-widget-container" >
                                <div id="tradingview_8fbfe" style={{ height: '600px' }}></div>
                                <div class="tradingview-widget-copyright-AA"><a href="https://www.tradingview.com/symbols/TSX-TTNM/" rel="noopener" target="_blank"><span class="blue-text">TTNM Chart</span></a> by TradingView</div>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="form-subheader" >Analyst Coverage
                        </div>

                    </Row>
                    <div className='contact-images-container'>
                        <Container fluid style={{ margin: '0' }}>

                            <Row className="justify-content-center" >
                                <Col className='col-md-auto contacts'><img style={{ maxWidth: "100%", height: "auto", width: "auto" }} className='coverage-image' src={Analysts} /></Col>
                    </Row>
                    {/*<Row className="justify-content-center" >*/}
                    {/*    <Col className='col-md-auto contacts'><img className='coverage-image' src={Desjardins} /></Col>*/}
                    {/*    <Col className='col-md-auto'><img className='coverage-image' src={Cormark} /></Col>*/}
                    {/*</Row>*/}
                    
                    {/*<Row  className="justify-content-center">    */}
                    {/*    <Col className='col-md-auto'><img className='coverage-image' src={Paradigm} /></Col>*/}
                    {/*    <Col className='col-md-auto'><img className='coverage-image' src={PIFinacial} /></Col>*/}
                    {/*</Row>*/}
                    {/*<Row className="justify-content-center" >*/}
                    {/*    <Col className='col-md-auto'><img className='coverage-image' src={Haywood} /></Col>*/}
                    {/*    <Col className='col-md-auto'><div className='coverage-image' style={{width:'690px', height:'1'}}></div></Col>*/}
                    {/*</Row>*/}
                    </Container>
                    </div>
                    <Row>
                        <div className="form-subheader" >Investor Relations Contact Form
                        </div>
                        <div className="quote-form " style={{ width: '100%', alignItems: 'center', marginTop: '0px', marginBottom: '20px', display: 'flex' }}>
                            <form onSubmit={sendEmail}>
                                <ul className="form-style-1" >
                                    <li>
                                        <label>Name *<span className="required" ></span></label>
                                        <input required="required" type="text" name="name" className="field-long" />
                                    </li>
                                    

                                    <li>
                                        <label>Email <span className="required" ></span></label>
                                        <input type="email" name="email" className="field-long" />
                                    </li>

                                    <li>
                                        <label>Message <span className="required"></span></label>
                                        <input type="search" name="message" className="field-long" style={{ height: '10vw' }} />
                                    </li>


                                    <input type="submit" value="Submit Form" />
                                    <p style={{ fontSize: "0.9rem !important", fontStyle: "italic", textAlign: "center" }}>{subscriptionFormMessage}</p>
                                </ul>



                            </form>

                        </div>

                    </Row>




                </Container>
            </div>


          
        </>
    )
}

export default Investors
