import React from 'react';
import Customers from '../assets/customers.png';
import bambooHR from '../assets/bambooHR.png'
import WarehouseLogin from '../assets/warehouse-login.png'
import Bamboo from '../assets/bamboo-drivers.jpg'
import Fusion from '../assets/fusion.png'
import UKG from '../assets/ukg.jpg'
import Truck from '../assets/trucking.png'
import Drivers from '../assets/drivers.png'
import { Link } from 'react-router-dom';
import "../styles/Login.css";
import { makeStyles } from '@material-ui/core/styles';
import '../styles/DigitalSolutions.css';
import { Breadcrumb } from 'semantic-ui-react';
import { Container, Row, Col } from "react-bootstrap";
import { LoginCards } from '../components/LoginCards'

const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'login', content: 'LOGIN', active: true },
]

function handleClick(event) {
    event.preventDefault();
}

function Login() {

    {/**breadcrumb */ }
    const useStyles = makeStyles((theme) => ({
        root: {
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
    }));


    return (
        <>

            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: "#fff" }} />
            </div>

            <div className="login-container">
                <div className="login-header">Login</div>

                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs="auto" className="p-4">
                            <Link to={{ pathname: "http://estat.ttgi.com/WebApp/Login/Login.aspx?ReturnUrl=%2FWebApp%2FWebEnterpriseDefaultLanding.aspx" }} target="_blank">
                                <LoginCards bgImage={Customers} name="CUSTOMERS" />
                            </Link>
                        </Col>
                        <Col xs="auto" className="p-4">
                            <Link to={{ pathname: "https://wms.ttgi.com/wms_login.asp" }} target="_blank">
                                <LoginCards bgImage={WarehouseLogin} name="WAREHOUSE" />
                            </Link>
                        </Col>
                        <Col xs="auto" className="p-4">
                            <Link to={{ pathname: "https://fusion.ttgi.com" }} target="_blank">
                                <LoginCards bgImage={Fusion} name="CARRIERS" />
                            </Link>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="auto" className="p-4">
                            <Link to={{ pathname: "https://drivers.ttgi.com/Account/Login?ReturnUrl=%2F" }} target="_blank">
                                <LoginCards bgImage={Drivers} name="DRIVER" name2="PORTAL" />
                            </Link>
                        </Col>
                        <Col xs="auto" className="p-4">
                            <Link to={{ pathname: "https://ultipro.ttgi.com/" }} target="_blank">
                                <LoginCards bgImage={bambooHR} name="EMPLOYEE" name2="PORTAL" />
                            </Link>
                        </Col>
                        {/* <Col xs="auto" className="p-4">
                            <Link to={{ pathname: "https://tw11.ultipro.ca/login.aspx" }} target="_blank">
                                <LoginCards bgImage={UKG} name="DRIVERS" />
                            </Link>
                        </Col>
                        <Col xs="auto" className="p-4">
                            <Link to={{ pathname: "https://ct288.isaachosting.ca/ISAACInRealTime/Security/Login" }} target="_blank">
                                <LoginCards bgImage={Truck} name="DRIVERS" name2="(Isaac)" />
                            </Link>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </>

    );
}

export default Login;
