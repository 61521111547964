import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumb } from 'semantic-ui-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Divider from '@material-ui/core/Divider';
import '../styles/Forms.css';
//import claimForm from '../assets/claim_Form.pdf';
import creditApplication from '../assets/credit_Application.pdf';
import creditAuthorization from '../assets/credit_Card_Auth.pdf';
import TALIClaimForm from '../assets/TALI_Claim_Form.pdf';
import TLIClaimForm from '../assets/TLI_Claim_Form.pdf';
import TTSIClaimForm from '../assets/TTSI_Claims_Form.pdf';
import claimProceedure from '../assets/Claims_Proceedures.pdf';
// import { useHistory } from "react-router-dom";
import Modal from 'react-modal';

const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'forms', content: 'FORMS', active: true },
]




// function handleClick(event) {
//     event.preventDefault();
// }


// const useStyles = makeStyles((theme) => ({
//     root: {
//       width: '100%',
//       maxWidth: 360,
//       backgroundColor: '#000',
//       alignItems: 'center',

//     },
//   }));

function Forms() {
    {/**breadcrumb */ }
    // const useStyles = makeStyles((theme) => ({
    //     root: {
    //         '& > * + *': {
    //             marginTop: theme.spacing(2),
    //         },
    //     },
    // }));

    /*************** REACT MODAL VARS AND FUNCTIONS ***************/

    // var to help show and hide modal
    const [showModal, setShowModal] = useState(false);

    // func for modal show and hide
    function handleOpenModal() {
        setShowModal(true);
    }

    function handleCloseModal() {
        setShowModal(false);
    }

    // styling modal 
    const customStyles = {
        content: {
            position: "fixed",
            //display: "flex",
            justifyContent: "center",
            top: "35%",
            left: "38%",
            width: '20%',
            height: '200px',
            zIndex: "1001",
            overflowY: "auto"
        },
    };

    //// styling modal 
    //const customStyles = {
    //    content: {
    //        position: "fixed",
    //        //display: "flex",
    //        justifyContent: "center",
    //        top: "35%",
    //        left: "38%",
    //        width: '20%',
    //        height: '200px',
    //        zIndex: "1001",
    //        overflowY: "auto"
    //    },
    //};

    
    // modal display func    
    function setModalDetails() {
        return (
            <Modal
                isOpen={showModal}
                contentLabel="close modal"
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
                style={customStyles}
            >
                <div class="container h-100">
                    <div class="row h-100 justify-content-center">
                        <h1>Select your claim</h1>
                        <a href={TALIClaimForm} target="_blank">Titanium American Logistics Inc.</a>
                        <a href={TLIClaimForm} target="_blank">Titanium Logistics Inc.</a>
                        <a href={TTSIClaimForm} target="_blank">Titanium Trucking Services Inc.</a>
                    </div>
                </div>
            </Modal>);
    }

    /*************** REACT MODAL VARS AND FUNCTIONS ***************/


    return (
        <>
            {setModalDetails()}
            {/**breadcrumb */}
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px', textDecoration: 'none' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff' }} />
            </div>


            {/**header: Forms */}
            <div className="forms-header">Forms</div>
            <div className="forms-container" >
                {/**forms */}
                <div className="forms" >

                    <List sx style={{ width: 'auto', height: 'auto' }}>
                        <ListItem style={{ marginBottom: '8px' }}>
                            <ListItemAvatar>
                                <div className="pdficon"><PictureAsPdfIcon fontSize="large" /></div>
                            </ListItemAvatar>
                            {/*<div className="pdf-name"><a href={claimForm} target="_blank" rel="noopener noreferrer" style={{ marginBottom: '10px' }}>Claim</a></div>*/}
                            <div className="pdf-name"><a onClick={() => handleOpenModal()} style={{ marginBottom: '10px' }}>Claims</a></div>
                        </ListItem>
                        <Divider variant="inset" component="li" style={{ height: '4px' }} />
                        <ListItem style={{ marginBottom: '8px' }}>
                            <ListItemAvatar>
                                <div className="pdficon"><PictureAsPdfIcon fontSize="large" /></div>
                            </ListItemAvatar>
                            <div className="pdf-name"><a href={claimProceedure} target="_blank" rel="noopener noreferrer">Claim Procedure</a></div>
                        </ListItem>
                        <Divider variant="inset" component="li" style={{ color: 'black', height: '4px' }} />
                        <ListItem style={{ marginBottom: '8px' }}>
                            <ListItemAvatar>
                                <div className="pdficon"><PictureAsPdfIcon fontSize="large" /></div>
                            </ListItemAvatar>
                            <div className="pdf-name">
                                <a href={creditApplication} target="_blank" rel="noopener noreferrer">Credit Application</a>
                            </div>
                        </ListItem>
                        <Divider variant="inset" component="li" style={{ height: '4px', width: '700px' }} />
                        <ListItem style={{ marginBottom: '8px' }}>
                            <ListItemAvatar>
                                <div className="pdficon"><PictureAsPdfIcon fontSize="large" /></div>
                            </ListItemAvatar>
                            <div className="pdf-name" >
                                <a href={creditAuthorization} target="_blank" rel="noopener noreferrer">Credit Card Authorization</a>
                            </div>
                        </ListItem>
                    </List>
                </div>

            </div>
            < br />
            < br />
            < br />



        </>
    )
}

export default Forms